import * as yup from "yup";

export const briefDataLogSchema = yup
  .object({
    id: yup.string().required(),
    requestId: yup.string().required(),
    workspaceId: yup.string().nullable(),
    info: yup.mixed().required(),
    createdAt: yup.string().defined(),
  })
  .camelCase();

export type BriefDataLog = yup.InferType<typeof briefDataLogSchema>;

export const pageInfoSchema = yup
  .object({
    cursor: yup.string().optional(),
    hasNext: yup.boolean().required(),
  })
  .camelCase();

export const paginatedWithOffsetDataLogSchema = yup
  .object({
    pageInfo: pageInfoSchema.required(),
    dataLogList: yup.array(briefDataLogSchema).required(),
  })
  .camelCase();

export type PaginatedWithOffsetDataLog = yup.InferType<
  typeof paginatedWithOffsetDataLogSchema
>;
