import { createReducer } from "@reduxjs/toolkit";

import * as webhookAction from "../actions/webhook";
import * as workspaceWebhookAction from "../actions/workspaceWebhook";
import { WorkspaceWebhook } from "../types/workspaceWebhook";

export interface WorkspaceWebhookState {
  readonly workspaceWebhooks: WorkspaceWebhook[];
  readonly isFetching: boolean;
}

const defaultState: WorkspaceWebhookState = {
  workspaceWebhooks: [],
  isFetching: false,
};

export const workspaceWebhookReducer = createReducer<WorkspaceWebhookState>(
  defaultState,
  builder => {
    builder
      .addCase(
        workspaceWebhookAction.StartedFetchingWorkspaceWebhookList,
        state => {
          state.workspaceWebhooks = [];
          state.isFetching = true;
        }
      )
      .addCase(
        workspaceWebhookAction.GotWorkspaceWebhookList,
        (state, action) => {
          state.workspaceWebhooks = action.payload;
          state.isFetching = false;
        }
      )
      .addCase(webhookAction.WebhookRemoved, (state, action) => {
        const { webhookId } = action.payload;

        state.workspaceWebhooks = state.workspaceWebhooks.filter(
          w => w.webhookId !== webhookId
        );
      });
  }
);
