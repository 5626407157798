import * as React from "react";

import { useWorkspaceWebhookActionCreator } from "../actions/workspaceWebhook";

export function useWorkspaceWebhookResource() {
  const { listWorkspaceWebhooks } = useWorkspaceWebhookActionCreator();

  const queryWorkspaceWebhook = React.useCallback(
    (workspaceId: string) => {
      listWorkspaceWebhooks(workspaceId);
    },
    [listWorkspaceWebhooks]
  );

  return { queryWorkspaceWebhook };
}
