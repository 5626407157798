import { Text } from "@fluentui/react";
import cn from "classnames";
import React from "react";

import { CopyButton } from "../CopyButton";
import styles from "./styles.module.scss";

interface CopyBlockProps {
  className?: string;
  textClassName?: string;
  content: string;
  method?: string;
}

export function CopyBlockImpl(props: CopyBlockProps) {
  const { className, textClassName, content, method } = props;
  return (
    <div className={cn(styles["container"], className)}>
      <div className={styles["main"]}>
        {method && <Text className={styles["method"]}>{method}</Text>}
        <Text className={cn(styles["url"], textClassName)}>{content}</Text>
      </div>
      <div className={styles["copy-wrapper"]}>
        <CopyButton className={styles["copy"]} text={content} showText={true} />
      </div>
    </div>
  );
}

export function CopyBlock(args: CopyBlockProps) {
  return <CopyBlockImpl {...args} />;
}
