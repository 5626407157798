import * as React from "react";
import { useSelector } from "react-redux";

import { useFormActionCreator } from "../actions/form";
import {
  CreateWorkspaceModal,
  useCreateWorkspaceModalHandle,
} from "../components/CreateWorkspaceModal";
import DataNotSavedPrompt from "../components/DataNotSavedPrompt";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import {
  ExtractorSettingSection,
  FormImageSettingProps,
  useExtractorSettingHandle,
} from "../components/ExtractorSetting";
import ImportFormConfirmModal, {
  useImportFormConfirmModalHandle,
} from "../components/ImportFormConfirmModal";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import ScriptEditorModal, {
  useScriptEditorHandle,
} from "../components/ScriptEditorModal";
import { UserFeatureFlag } from "../constants";
import {
  PubliclyAvailableLLMModels,
  RestrictedLLMModels,
} from "../constants/llmModel";
import {
  PrebuiltExtractorDefinition,
  isEditablePrebuiltExtractors,
  isPrebuiltExtractors,
} from "../constants/prebuiltExtractor";
import HeaderContainer from "../containers/Header";
import errors, { FOCRError } from "../errors";
import { useCommonFormContainerState } from "../hooks/form";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useToast } from "../hooks/toast";
import { PathParam } from "../models";
import { RootState } from "../redux/types";
import { ExtractorSettings } from "../types/extractorSettings";
import { BriefExtractorMapper } from "../types/mappers/extractor";
import { ProcessingMode } from "../types/processingMode";
import { FormNavBarLayout, FormNavTabKey } from "./FormNavBarLayout";

function useFormSettingContainer() {
  const { formId } = useUnsafeParams<PathParam>();
  const containerState = useCommonFormContainerState(formId);

  const {
    props: createWorkspaceModalProps,
    isCreating: isCreatingWorkspace,
    onCreateExtractorWorkspaceClick,
  } = useCreateWorkspaceModalHandle(
    containerState.state === "success"
      ? BriefExtractorMapper.fromDetailForm(containerState.form)
      : null
  );

  const { updateFormExtractorSettings, handleConflictAndSaveForm } =
    useFormActionCreator();

  const toast = useToast();

  const onSaveExtractorSettings = React.useCallback(
    async (extractorSettings: ExtractorSettings) => {
      try {
        await updateFormExtractorSettings(extractorSettings);
        await handleConflictAndSaveForm();
        toast.success("form_editor.setting_is_saved");
      } catch (e: unknown) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          console.error(e);
          toast.error(
            errors.UnknownError.messageId,
            undefined,
            errors.UnknownError.detail
          );
        }
      }
    },
    [handleConflictAndSaveForm, toast, updateFormExtractorSettings]
  );

  const useExtractorSettingHandleReturns = useExtractorSettingHandle({
    form: containerState.state === "success" ? containerState.form : undefined,
    onSave: onSaveExtractorSettings,
  });

  const { exportForm, importForm } = useFormActionCreator();

  const onConfirmImportForm = React.useCallback(
    async (file: File) => {
      if (containerState.state !== "success") {
        return;
      }
      return await importForm(file, containerState.form.id);
    },
    [importForm, containerState]
  );

  const {
    onClickImport: onClickImportForm,
    isImporting: isImportingForm,
    props: importFormConfirmModalProps,
  } = useImportFormConfirmModalHandle(onConfirmImportForm);

  const { onOpenScriptModal, props: scriptModalProps } = useScriptEditorHandle({
    scriptType: "post_process_script",
    form:
      containerState.state === "success"
        ? {
            ...containerState.form,
            config: {
              ...containerState.form.config,
              post_process_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .postProcessingScript ??
                containerState.form.config.post_process_script,
            },
          }
        : undefined,
    onSaveScript: useExtractorSettingHandleReturns.onChangePostProcessingScript,
  });

  const {
    onOpenScriptModal: onOpenTransformResponseScriptModal,
    props: transformResponseScriptModalProps,
  } = useScriptEditorHandle({
    scriptType: "transform_response_script",
    form:
      containerState.state === "success"
        ? {
            ...containerState.form,
            config: {
              ...containerState.form.config,
              transform_response_script:
                useExtractorSettingHandleReturns.extractorSettings
                  .transformResponseScript ??
                containerState.form.config.transform_response_script,
            },
          }
        : undefined,
    onSaveScript:
      useExtractorSettingHandleReturns.onChangeTransformResponseScript,
  });

  const { resourceOwnerId } = useSelector(
    (state: RootState) => state.resourceOwner
  );

  const [isExporting, setIsExporting] = React.useState(false);
  const onExport = React.useCallback(async () => {
    if (containerState.state !== "success") {
      return;
    }
    if (resourceOwnerId == null) {
      return;
    }
    setIsExporting(true);
    try {
      await exportForm(containerState.form.id, resourceOwnerId);
    } catch {
      toast.error("error.fail_to_export_form");
    }
    setIsExporting(false);
  }, [
    //@ts-expect-error container state can be not success
    containerState.form?.id,
    containerState.state,
    exportForm,
    resourceOwnerId,
    toast,
  ]);

  const formImageProps: FormImageSettingProps | undefined =
    React.useMemo(() => {
      if (containerState.state !== "success") {
        return undefined;
      }
      if (
        isPrebuiltExtractors(
          containerState.form.config.prebuilt_extractor ?? ""
        ) &&
        !isEditablePrebuiltExtractors(
          containerState.form.config.prebuilt_extractor ?? ""
        )
      ) {
        return undefined;
      }
      return {
        imageType: isPrebuiltExtractors(
          containerState.form.config.prebuilt_extractor ?? ""
        )
          ? "thumbnail"
          : "master",
        imageSrc: containerState.form.image ?? null,
      };
    }, [
      // @ts-expect-error no data if state not success
      containerState.form?.config.prebuilt_extractor,
      // @ts-expect-error no data if state not success
      containerState.form?.image,
      containerState.state,
    ]);

  const prebuiltExtractorDefinition =
    containerState.state === "success" &&
    containerState.form.config.prebuilt_extractor &&
    isPrebuiltExtractors(containerState.form.config.prebuilt_extractor)
      ? PrebuiltExtractorDefinition[
          containerState.form.config.prebuilt_extractor
        ]
      : undefined;

  const canUseRestrictedLLMModels = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.MoreLLMModels)
  );

  React.useEffect(() => {
    useExtractorSettingHandleReturns.dataLogSettingHandle.queryDataLogSettingIfEnabled(
      formId,
      "form"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      formId,
      containerState,
      highlightWorkspaceSetups:
        containerState.state === "success" &&
        containerState.form.workspaces.length === 0,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onClickImportForm,
      isImportingForm,
      importFormConfirmModalProps,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      isExporting,
      onExport,
      formImageProps,
      prebuiltExtractorDefinition,
      canUseRestrictedLLMModels,
    }),
    [
      formId,
      containerState,
      createWorkspaceModalProps,
      onCreateExtractorWorkspaceClick,
      isCreatingWorkspace,
      useExtractorSettingHandleReturns,
      onClickImportForm,
      isImportingForm,
      importFormConfirmModalProps,
      onOpenScriptModal,
      onOpenTransformResponseScriptModal,
      scriptModalProps,
      transformResponseScriptModalProps,
      isExporting,
      onExport,
      formImageProps,
      prebuiltExtractorDefinition,
      canUseRestrictedLLMModels,
    ]
  );
}

export default function FormSettingContainer() {
  const props = useFormSettingContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <LoadingModal isOpen={props.containerState.state === "loading"} />
      <LoadingModal
        messageId="common.importing"
        isOpen={props.isImportingForm}
      />
      <Main hasTop={true}>
        {props.containerState.state === "error" ? (
          <ErrorPlaceholder messageId="common.fail_to_fetch_form" />
        ) : props.containerState.state === "success" ? (
          <>
            <FormNavBarLayout
              selectedTab={FormNavTabKey.Settings}
              form={props.containerState.form}
            >
              <ExtractorSettingSection
                extractor={BriefExtractorMapper.fromDetailForm(
                  props.containerState.form
                )}
                workspaces={props.containerState.form.workspaces}
                showWorkspaceSetups={true}
                onCreateExtractorWorkspaceClick={
                  props.onCreateExtractorWorkspaceClick
                }
                useHandleReturns={props.useExtractorSettingHandleReturns}
                isImportingForm={props.isImportingForm}
                onClickImportForm={props.onClickImportForm}
                onClickEditPostProcessingScript={props.onOpenScriptModal}
                onClickEditTransformResponseScript={
                  props.onOpenTransformResponseScriptModal
                }
                isExportingForm={props.isExporting}
                onClickExportForm={props.onExport}
                formImageProps={props.formImageProps}
                allowedProcessingModes={[
                  ProcessingMode.PerPage,
                  ...(props.prebuiltExtractorDefinition?.isUsingLLM ||
                  props.containerState.form.config.auto_extraction_items.includes(
                    "llm_completion"
                  )
                    ? [ProcessingMode.PerFile]
                    : []),
                  ProcessingMode.MultipleDocumentsPerPage,
                ]}
                allowedLLMModels={
                  props.prebuiltExtractorDefinition?.isUsingLLM
                    ? [
                        ...PubliclyAvailableLLMModels,
                        ...(props.canUseRestrictedLLMModels
                          ? RestrictedLLMModels
                          : []),
                      ]
                    : undefined
                }
              />
            </FormNavBarLayout>
            <CreateWorkspaceModal {...props.createWorkspaceModalProps} />
            <LoadingModal
              messageId="common.uploading"
              isOpen={props.isCreatingWorkspace}
            />
            {props.importFormConfirmModalProps.file != null ? (
              <ImportFormConfirmModal {...props.importFormConfirmModalProps} />
            ) : null}
            {props.scriptModalProps != null ? (
              <ScriptEditorModal {...props.scriptModalProps} />
            ) : null}

            {props.transformResponseScriptModalProps != null ? (
              <ScriptEditorModal {...props.transformResponseScriptModalProps} />
            ) : null}

            <DataNotSavedPrompt
              isDataChanged={props.useExtractorSettingHandleReturns.isFormDirty}
              titleTextId="extractor_setting.form_not_saved_prompt.title"
              messageTextId="extractor_setting.form_not_saved_prompt.save_warning"
              backTextId="extractor_setting.form_not_saved_prompt.go_back"
              continueTextId="extractor_setting.form_not_saved_prompt.leave_page"
            />
          </>
        ) : null}
      </Main>
    </Layout>
  );
}
