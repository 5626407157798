import { AdvancedPatternMatching } from "../../types/advancedPatternMatching";
import { PaginatedConfigSnapshot } from "../../types/configSnapshot";
import { DetailedForm } from "../../types/form";

export interface AdvanceTokenSetupVersionHistoryModalRequest {
  form: DetailedForm;
  initialConfigSnapshots?: PaginatedConfigSnapshot;
}

export enum AdvanceTokenSetupVersionHistoryModalResponseType {
  Restored,
  Cancelled,
}

export type AdvanceTokenSetupVersionHistoryModalResponse =
  | {
      type: AdvanceTokenSetupVersionHistoryModalResponseType.Cancelled;
    }
  | {
      type: AdvanceTokenSetupVersionHistoryModalResponseType.Restored;
      acceptedValue: AdvancedPatternMatching;
    };

export type AdvanceTokenSetupVersionHistoryModalBundleHandle = {
  open: (
    request: AdvanceTokenSetupVersionHistoryModalRequest
  ) => Promise<AdvanceTokenSetupVersionHistoryModalResponse>;
};

export type AdvanceTokenSetupVersionHistoryModalBundleProps = {
  handleRef: React.RefObject<
    AdvanceTokenSetupVersionHistoryModalBundleHandle | undefined
  >;
};
