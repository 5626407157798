import {
  WorkspaceWebhookList,
  workspaceWebhookListSchema,
} from "../../types/workspaceWebhook";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export interface WorkspaceWebhookApiClient {
  listWorkspaceWebhooks: (
    resourceOwnerId: string,
    workspaceId: string
  ) => Promise<WorkspaceWebhookList>;
}

export function withWorkspaceWebhookApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listWorkspaceWebhooks(
      resourceOwnerId: string,
      workspaceId: string
    ): Promise<WorkspaceWebhookList> {
      return this.lambda(
        "workspace_webhook:list",
        this.injectOptionalFields(
          {},
          {
            resource_owner_id: resourceOwnerId,
            workspace_id: workspaceId,
          }
        ),
        workspaceWebhookListSchema,
        null
      );
    }
  };
}
