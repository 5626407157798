import React, { useCallback, useState } from "react";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useFormActionCreator } from "../actions/form";
import { apiClient } from "../apiClient";
import AdvanceTokenSetupEditor from "../components/AdvanceTokenSetupEditor";
import {
  AdvanceTokenSetupVersionHistoryModal,
  useAdvanceTokenSetupVersionHistoryModalHandle,
} from "../components/AdvanceTokenSetupVersionHistoryModal";
import { Layout, Main, Top } from "../components/Layout";
import { UserFeatureFlag } from "../constants";
import { CONFIG_SNAPSHOT_PAGE_SIZE } from "../constants/layout";
import { AdvanceTokenSetupEditorProvider } from "../contexts/advanceTokenSetupEditor";
import { FormEditorProvider, useFormEditor } from "../contexts/formEditor";
import { useCommonFormContainerState } from "../hooks/form";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { MenuItem } from "../types/advancedTokenSetup/table";
import { PaginatedConfigSnapshot } from "../types/configSnapshot";
import { DetailedForm } from "../types/form";
import HeaderContainer from "./Header";

type PathParam = {
  formId: string;
};

const AdvanceTokenSetupImpl = () => {
  const { formId } = useUnsafeParams<PathParam>();

  const containerState = useCommonFormContainerState(formId);
  const { open: openVersionHistory, triggerProps: versionHistoryProps } =
    useAdvanceTokenSetupVersionHistoryModalHandle();

  const onOpenVersionHistory = React.useCallback(
    async (initialConfigSnapshots: PaginatedConfigSnapshot) => {
      if (containerState.state !== "success") {
        return;
      }

      return openVersionHistory({
        form: containerState.form,
        initialConfigSnapshots,
      });
    },
    [openVersionHistory, containerState]
  );

  const [selectedMenu, setSelectedMenu] = useState(MenuItem.TagMerchant);

  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      {containerState.state === "success" && (
        <Main hasTop={true}>
          <AdvanceTokenSetupEditor
            form={containerState.form}
            onOpenVersionHistory={onOpenVersionHistory}
            selectedMenu={selectedMenu}
            onSelectMenu={setSelectedMenu}
          />
          <AdvanceTokenSetupVersionHistoryModal {...versionHistoryProps} />
        </Main>
      )}
    </Layout>
  );
};

const AdvanceTokenSetupAdapter = React.memo(() => {
  const {
    saveForm,
    updateMerchantPatternMatching,
    restoreFormConfig: restoreFormConfigImpl,
  } = useFormActionCreator();
  const { isSavingForm, form } = useFormEditor();
  const isVersionSnapshotsEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled()(UserFeatureFlag.FormVersionSnapshots)
  );
  const { handleConflict } = useConfirmModalActionCreator();
  const fetchConfigSnapshots = useCallback(
    (form: DetailedForm, cursor?: string) => {
      return apiClient.listConfigSnapshots(
        "form",
        form.id,
        "advanced_pattern_matching",
        CONFIG_SNAPSHOT_PAGE_SIZE,
        cursor,
        form.resourceOwnerId ?? ""
      );
    },
    []
  );

  const restoreFormConfig = React.useCallback(
    async (configSnapshotId: string) => {
      return handleConflict(
        () => restoreFormConfigImpl(false, configSnapshotId),
        () => restoreFormConfigImpl(true, configSnapshotId),
        {
          titleId: "form_editor.form_modifed_prompt.title",
          messageId: "form_editor.form_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [restoreFormConfigImpl, handleConflict]
  );

  const editConfigSnapshotInfo = React.useCallback(
    async (
      configSnapshotId: string,
      retrievedAt: string,
      name: string,
      note?: string
    ) => {
      return handleConflict(
        () =>
          apiClient.editConfigSnapshotInfo(
            configSnapshotId,
            retrievedAt,
            false,
            name,
            note
          ),
        () =>
          apiClient.editConfigSnapshotInfo(
            configSnapshotId,
            retrievedAt,
            true,
            name,
            note
          ),
        {
          titleId: "error.config_snapshot_modifed_prompt.title",
          messageId: "error.config_snapshot_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [handleConflict]
  );

  const bookmarkConfigSnapshot = React.useCallback(
    async (
      configSnapshotId: string,
      retrievedAt: string,
      name: string,
      note?: string
    ) => {
      return handleConflict(
        () =>
          apiClient.bookmarkConfigSnapshot(
            configSnapshotId,
            retrievedAt,
            false,
            name,
            note
          ),
        () =>
          apiClient.bookmarkConfigSnapshot(
            configSnapshotId,
            retrievedAt,
            true,
            name,
            note
          ),
        {
          titleId: "error.config_snapshot_modifed_prompt.title",
          messageId: "error.config_snapshot_modifed_prompt.desc",
          actionId: "common.save_and_overwrite",
        }
      );
    },
    [handleConflict]
  );

  const deleteConfigSnapshot = React.useCallback(
    async (configSnapshotId: string) => {
      return apiClient.deleteConfigSnapshot(configSnapshotId);
    },
    []
  );

  const getConfigSnapshot = React.useCallback(
    async (configSnapshotId: string) => {
      return apiClient.getConfigSnapshot(configSnapshotId);
    },
    []
  );
  const fetchConfigSnapshotIndex = React.useCallback(
    async (form: DetailedForm) => {
      return apiClient.listConfigSnapshotIndex(
        "form",
        form.id,
        "advanced_pattern_matching",
        form.resourceOwnerId ?? ""
      );
    },
    []
  );
  return (
    <AdvanceTokenSetupEditorProvider
      saveForm={saveForm}
      updateMerchantPatternMatching={updateMerchantPatternMatching}
      isSavingForm={isSavingForm}
      isVersionSnapshotsEnabled={isVersionSnapshotsEnabled}
      fetchConfigSnapshots={fetchConfigSnapshots}
      editConfigSnapshot={editConfigSnapshotInfo}
      deleteConfigSnapshot={deleteConfigSnapshot}
      bookmarkConfigSnapshot={bookmarkConfigSnapshot}
      restoreConfigSnapshot={restoreFormConfig}
      getConfigSnapshot={getConfigSnapshot}
      fetchConfigSnapshotIndex={fetchConfigSnapshotIndex}
      form={form}
    >
      <AdvanceTokenSetupImpl />
    </AdvanceTokenSetupEditorProvider>
  );
});

const AdvanceTokenSetup = React.memo(() => {
  return (
    <FormEditorProvider>
      <AdvanceTokenSetupAdapter />
    </FormEditorProvider>
  );
});

export default AdvanceTokenSetup;
