export const README_IO_PAGE_ORIGIN = "https://help.formx.ai";

export const README_IO_PAGE_URL = {
  referenceDocumentExtraction:
    README_IO_PAGE_ORIGIN + "/reference/document-extraction",
  referenceDetectPII: README_IO_PAGE_ORIGIN + "/reference/detect-pii",
  referenceDetectDocuments:
    README_IO_PAGE_ORIGIN + "/reference/detect-documents",
  referenceDocumentExtractionToWorkspace:
    README_IO_PAGE_ORIGIN + "/reference/document-extraction-to-workspace",
  referenceV2DatalogFile: README_IO_PAGE_ORIGIN + "/reference/v2-datalog-file",
};
