import { CustomModel } from "./customModel";
import { DetailedForm } from "./form";
import { OCRConfig } from "./formConfig";
import { DetailFormGroup } from "./formGroup";
import { LLMModel, isLLMModel } from "./llmModel";
import { ProcessingMode } from "./processingMode";

export interface ExtractorSettings {
  extractorName: string;
  postProcessingScript?: string;
  transformResponseScript?: string;
  newImage?: File | null; // undefined = unset, null = remove image from backend
  ocrConfig?: OCRConfig;
  processingMode?: `${ProcessingMode}`;
  llmModelInUse?: LLMModel;
  preverseHorizontalWhiteSpace?: boolean;
  preverseVerticalWhiteSpace?: boolean;
  splitPromptForLineItems?: boolean;

  // For enabling advanced pattern matching
  // in custom model but the actual value is
  // stored in the form config
  advancedPatternMatchingEnabled?: boolean;
  imageQualityEnabled?: boolean;
  extractionRetentionEnabled?: boolean;
  extractionRetentionPeriod?: number;
}

export class ExtractorSettingsAccessor {
  data: ExtractorSettings;

  constructor(data: ExtractorSettings) {
    this.data = data;
  }

  static create(
    form?: DetailedForm,
    formGroup?: DetailFormGroup,
    customModel?: CustomModel
  ) {
    const formOrFormGroup = form ?? formGroup;

    const data = {
      extractorName: customModel?.name ?? formOrFormGroup?.name ?? "",
      postProcessingScript: formOrFormGroup?.config.post_process_script,
      transformResponseScript:
        formOrFormGroup?.config.transform_response_script,
      ocrConfig: formOrFormGroup?.config.ocr_config,
      processingMode: formOrFormGroup?.config.processing_mode,
      llmModelInUse: isLLMModel(form?.config.llm_model_in_use)
        ? form?.config.llm_model_in_use
        : undefined,
      imageQualityEnabled:
        formOrFormGroup?.config.image_quality_config?.enabled,
      extractionRetentionEnabled:
        formOrFormGroup?.config.extraction_retention_enabled,
      extractionRetentionPeriod:
        formOrFormGroup?.config.extraction_retention_period,
    } as ExtractorSettings;
    if (customModel !== undefined) {
      data.preverseHorizontalWhiteSpace =
        customModel?.config?.llmParameters?.should_preserve_horizontal_whitespace;
      data.preverseVerticalWhiteSpace =
        customModel?.config?.llmParameters?.should_preserve_vertial_whitespace;
      data.splitPromptForLineItems =
        customModel?.config?.llmParameters?.should_separate_prompt_by_fields;
    } else if (form !== undefined) {
      data.preverseHorizontalWhiteSpace =
        form.config?.llm_completions?.prebuilt?.parameters?.should_preserve_horizontal_whitespace;
      data.preverseVerticalWhiteSpace =
        form.config?.llm_completions?.prebuilt?.parameters?.should_preserve_vertial_whitespace;
      data.splitPromptForLineItems =
        form.config?.llm_completions?.prebuilt?.parameters?.should_separate_prompt_by_fields;
    }

    if (customModel !== undefined && form !== undefined) {
      data.advancedPatternMatchingEnabled =
        form.config?.auto_extraction_items.includes("merchant");
    }
    return new ExtractorSettingsAccessor(data);
  }
}
