import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { FormattedMessage, Values } from "@oursky/react-messageformat";
import * as React from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useLocale } from "../../contexts/locale";
import { RootState } from "../../redux/types";
import { ConfirmModalType, ConfirmationTask } from "../../types/confirmation";
import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  modalType: ConfirmModalType;
  titleId: string;
  messageId: string | string[];
  actionId: string;
  onCancel(): void;
  onConfirm(): void;
  messageValues?: Values;
  challenge?: string;
}

function _ConfirmModal(props: Props) {
  const { localized } = useLocale();
  const {
    isOpen,
    modalType,
    onCancel,
    onConfirm,
    actionId,
    titleId,
    messageId,
    messageValues,
    challenge,
  } = props;
  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.normal,
      title: localized(titleId),
      subText: localized(
        Array.isArray(messageId) ? messageId[0] : messageId,
        messageValues
      ),
    }),
    [localized, titleId, messageId, messageValues]
  );
  const [challengeText, setChallengeText] = React.useState("");
  const challengeTextRef = React.useRef(challengeText);
  challengeTextRef.current = challengeText;

  React.useEffect(() => {
    if (isOpen) setChallengeText("");
  }, [isOpen]);

  const onChallengeChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      if (value !== undefined) {
        setChallengeText(value);
      }
    },
    []
  );

  const onFormSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (challenge !== undefined && challenge === challengeTextRef.current) {
        onConfirm();
      }
    },
    [onConfirm, challenge]
  );

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
    >
      <form onSubmit={onFormSubmit}>
        {challenge && (
          <div className={styles["challenge"]}>
            <TextField
              label={localized("confirm.modal.challenge", { challenge })}
              value={challengeText}
              onChange={onChallengeChange}
              autoCapitalize="none"
              autoComplete="off"
            />
          </div>
        )}

        <DialogFooter>
          {modalType !== ConfirmModalType.Notify ? (
            <DefaultButton
              onClick={onCancel}
              text={localized("common.cancel")}
            />
          ) : null}
          {modalType === ConfirmModalType.Destory ? (
            <DefaultButton
              className={styles["destroy-button"]}
              onClick={onConfirm}
              text={localized(actionId)}
              disabled={challenge !== undefined && challenge !== challengeText}
            />
          ) : modalType === ConfirmModalType.Save ? (
            <DefaultButton
              className={styles["save-button"]}
              onClick={onConfirm}
              text={localized(actionId)}
            />
          ) : (
            <PrimaryButton onClick={onConfirm} text={localized(actionId)} />
          )}
        </DialogFooter>
      </form>
    </Dialog>
  );
}

const emptyConfirmationTask: ConfirmationTask = {
  modalType: ConfirmModalType.Normal,
  titleId: "",
  messageId: "",
  actionId: "",
  onCancel: () => {},
  onConfirm: () => {},
};

export const CommonConfirmModal = React.memo(_CommonConfirmModal);

function _CommonConfirmModal() {
  const { localized } = useLocale();

  const {
    modalType,
    onConfirm,
    onCancel,
    actionId,
    titleId,
    titleValues,
    messageId,
    messageValues,
    hideCancel = false,
  } = useSelector<RootState, ConfirmationTask>(
    state => state.confirmModal.pendingConfirmationTask ?? emptyConfirmationTask
  );
  const showConfirmationModal = useSelector<RootState, boolean>(
    state => state.confirmModal.isShowingConfirmModal
  );

  const dialogContentProps = useMemo(() => {
    if (!Array.isArray(messageId)) {
      return {
        type: DialogType.normal,
        title: titleId ? localized(titleId, titleValues) : "",
        subText: messageId ? localized(messageId as string, messageValues) : "",
      };
    } else {
      return {
        type: DialogType.normal,
        title: titleId ? localized(titleId, titleValues) : "",
      };
    }
  }, [titleId, localized, titleValues, messageId, messageValues]);

  return (
    <Dialog
      onDismiss={onCancel}
      dialogContentProps={dialogContentProps}
      hidden={!showConfirmationModal}
    >
      {Array.isArray(messageId) && (
        <div className="text-[#9B9997] flex flex-col gap-[16px] pb-[16px]">
          {(messageId as string[]).map((id, index) => {
            return (
              <div key={index}>
                <FormattedMessage id={id} />
              </div>
            );
          })}
        </div>
      )}
      <DialogFooter>
        {modalType !== ConfirmModalType.Notify && !hideCancel && (
          <DefaultButton onClick={onCancel} text={localized("common.cancel")} />
        )}
        {modalType === ConfirmModalType.Destory ? (
          <DefaultButton
            className={styles["destroy-button"]}
            onClick={onConfirm}
            text={actionId ? localized(actionId) : ""}
          />
        ) : modalType === ConfirmModalType.Save ? (
          <DefaultButton
            className={styles["save-button"]}
            onClick={onConfirm}
            text={actionId ? localized(actionId) : ""}
          />
        ) : (
          <PrimaryButton
            onClick={onConfirm}
            text={actionId ? localized(actionId) : ""}
          />
        )}
      </DialogFooter>
    </Dialog>
  );
}

export const ConfirmModal = React.memo(_ConfirmModal);
export default ConfirmModal;
