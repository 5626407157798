import { Values } from "@oursky/react-messageformat";

export enum ConfirmModalType {
  Destory,
  Save,
  Normal,
  Notify,
}
export interface ConfirmationTask {
  modalType: ConfirmModalType;
  titleId: string;
  titleValues?: Values;
  messageId?: string | string[];
  messageValues?: Values;
  actionId: string;
  hideCancel?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
