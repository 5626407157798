import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  Icon,
  IconButton,
  Label,
  Link,
  Text,
  TextField,
  Toggle,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import cntl from "cntl";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import {
  AZURE_ENGINE_API_OPTIONS,
  GOOGLE_ENGINE_API_OPTIONS,
  UserFeatureFlag,
} from "../../constants";
import { DEFAULT_DATA_LOG_RETENTION_PERIOD } from "../../constants/dataLog";
import { useLocale } from "../../contexts/locale";
import { usePrevious } from "../../hooks/common";
import { useAvailableEngines } from "../../hooks/extractorSetting";
import { useTeamPermission } from "../../hooks/permission";
import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../redux/types";
import { BriefWorkspace } from "../../types/briefWorkspace";
import { CustomModel } from "../../types/customModel";
import {
  BriefExtractor,
  mapExtractorTypeToMessageId,
} from "../../types/extractor";
import {
  ExtractorSettings,
  ExtractorSettingsAccessor,
} from "../../types/extractorSettings";
import { DetailedForm } from "../../types/form";
import { OCRConfigEngine } from "../../types/formConfig";
import { DetailFormGroup } from "../../types/formGroup";
import { LLMModel } from "../../types/llmModel";
import { ProcessingMode } from "../../types/processingMode";
import { deepEqual } from "../../utils/deepEqual";
import BetaTag from "../BetaTag";
import { CopyBlock } from "../CopyBlock";
import { DangerButton } from "../DangerButton";
import { FileDefaultButton } from "../FileButton";
import { Img } from "../Img";
import { DefaultButton } from "../WrappedMSComponents/Buttons";
import { DataLogSetting, useDataLogSettingHandle } from "./DataLogSetting";
import styles from "./styles.module.scss";

export function WorkspaceItem(props: {
  className?: string;
  workspace: BriefWorkspace;
}) {
  const { className, workspace } = props;
  return (
    <div className={cn(className, styles["workspace-item__container"])}>
      <Text className={styles["workspace-item__name"]}>{workspace.name}</Text>
      <RouterLink
        className="text-sm text-[#0078d4] font-normal flex items-center gap-[6px] leading-5 visited:text-[#0078d4]"
        to={`/workspace/${workspace.id}`}
      >
        <FormattedMessage id="extractor.setting.block.workspace_setups.connected_workspace.label.go_to_workspace" />
        <Icon iconName="Forward" />
      </RouterLink>
    </div>
  );
}

function ProcessingModeChoiceGroupOptionLabel(props: {
  text: string;
  description: string;
  disabled?: boolean;
}) {
  const { text, description, disabled } = props;
  const { localized } = useLocale();
  return (
    <div
      className={cn(styles["document-processing-mode-label__container"], {
        [styles["document-processing-mode-label__disabled"]]: disabled,
      })}
      title={
        disabled
          ? localized(
              "extractor_setting.document_processing_option.not_supported"
            )
          : undefined
      }
    >
      <div className={styles["document-processing-mode-label__text"]}>
        {text}
      </div>
      <div className={styles["document-processing-mode-label__description"]}>
        {description}
      </div>
    </div>
  );
}

export interface FormImageSettingProps {
  imageType: "master" | "thumbnail";
  imageSrc: string | null;
}

interface ExtractorSettingSectionProps {
  extractor: BriefExtractor;
  displayExtractorId?: string;
  workspaces: BriefWorkspace[];
  showWorkspaceSetups: boolean;
  onCreateExtractorWorkspaceClick: (extractor: BriefExtractor) => void;
  useHandleReturns: ReturnType<typeof useExtractorSettingHandle>;
  allowedProcessingModes: ProcessingMode[];
  isImportingForm?: boolean;
  onClickImportForm?: () => void;
  onClickEditPostProcessingScript: () => void;
  onClickEditTransformResponseScript: () => void;
  isExportingForm?: boolean;
  onClickExportForm?: () => void;
  formImageProps?: FormImageSettingProps;
  allowedLLMModels?: LLMModel[];
  isCustomModel?: boolean;
  importDisabled?: boolean;
  children?: React.ReactNode;
}

export function ImportExportSetting(props: {
  canImport: boolean;
  canExport: boolean;
  isImporting?: boolean;
  isExporting?: boolean;
  onClickImport?: () => void;
  onClickExport?: () => void;
  hasPermissionToEdit: boolean;
  isCustomModel?: boolean;
  importDisabled?: boolean;
}) {
  return (
    <>
      <div className={styles["general-settings__fields"]}>
        {props.canExport ? (
          <div>
            <Label
              className={cntl`${styles["general-settings__field-label"]}
              `}
            >
              <FormattedMessage id="extractor_setting.export_this_extractor" />
            </Label>
            {props.isCustomModel && (
              <div
                className={cntl`${styles["subtitle"]}
                  pb-[4px]`}
              >
                <div>
                  <FormattedMessage id="extractor_setting.export_custom_model_desc1" />
                </div>
                <div>
                  <FormattedMessage id="extractor_setting.export_custom_model_desc2" />
                </div>
              </div>
            )}
            <DefaultButton
              textId="extractor_setting.download_as_zip"
              iconProps={{ iconName: "Download" }}
              loading={props.isExporting}
              onClick={props.onClickExport}
              disabled={props.isExporting}
              reverseIconPosition={true}
            />
          </div>
        ) : null}

        {props.canImport ? (
          <div>
            <Label className={styles["general-settings__field-label"]}>
              <FormattedMessage id="extractor_setting.import_this_extractor" />
            </Label>
            <div
              className={cntl`${styles["subtitle"]}
                  pb-[4px]`}
            >
              <div>
                {props.isCustomModel ? (
                  <>
                    <div>
                      {" "}
                      <FormattedMessage id="extractor_setting.import_custom_model_desc1" />
                    </div>
                    <div>
                      <FormattedMessage id="extractor_setting.import_custom_model_desc2" />
                    </div>
                  </>
                ) : (
                  <>
                    <FormattedMessage id="extractor_setting.import_desc" />
                  </>
                )}
              </div>
            </div>
            <DefaultButton
              textId="extractor_setting.import_from_zip"
              iconProps={{ iconName: "Upload" }}
              loading={props.isImporting}
              onClick={props.onClickImport}
              disabled={
                props.isImporting ||
                !props.hasPermissionToEdit ||
                props.importDisabled
              }
              reverseIconPosition={true}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export function useExtractorSettingHandle({
  form,
  formGroup,
  customModel,
  onSave,
}: {
  form?: DetailedForm;
  formGroup?: DetailFormGroup;
  customModel?: CustomModel;
  onSave: (settings: ExtractorSettings) => Promise<void>;
}) {
  // Ref: field vs extractor type table: https://github.com/oursky/form-extractor/issues/2573#issuecomment-1711236258

  const { isFormChanged } = useSelector((state: RootState) => state.form);
  const { hasPermissionToEditResource } = useTeamPermission();
  const [hasError, setHasError] = useState(false);

  const dataLogSettingHandle = useDataLogSettingHandle();

  // Form State
  // NOTE: image state handled by action creator, no state for import / export
  const [extractorSettings, setExtractorSettings] = useState<ExtractorSettings>(
    ExtractorSettingsAccessor.create(form, formGroup, customModel).data
  );
  const initExtractorSettings = useRef<ExtractorSettings | undefined>();

  const prevForm = usePrevious(form);
  const prevCustomModel = usePrevious(customModel);

  const reloadExtractorSettings = useCallback(() => {
    const settings = ExtractorSettingsAccessor.create(
      form,
      formGroup,
      customModel
    ).data;
    setExtractorSettings(settings);
    initExtractorSettings.current = settings;
  }, [customModel, formGroup, form]);

  useEffect(() => {
    // update init state on upstream load complete
    const shouldInitial =
      initExtractorSettings.current === undefined &&
      (form || formGroup || customModel);

    // After imported, it will invalidate the custom model and form,
    // and then load the custom model first and then followed by the form loading
    const mayBeImported =
      (prevForm == null && form != null) ||
      (prevCustomModel == null && customModel != null);

    if (shouldInitial || mayBeImported) {
      reloadExtractorSettings();
    }
  }, [
    customModel,
    formGroup,
    form,
    prevForm,
    prevCustomModel,
    reloadExtractorSettings,
  ]);

  // Extractor Name Field (Form or Form Group)
  const onExtractorNameChange = useCallback((newName: string) => {
    setExtractorSettings(prev => ({
      ...prev,
      extractorName: newName,
    }));
  }, []);

  const isExtractorNameEmpty = useMemo(() => {
    return extractorSettings.extractorName.trim().length <= 0;
  }, [extractorSettings.extractorName]);

  // Image Field (Form Only)
  const onSelectImage = useCallback((files?: File[]) => {
    if (files == null || files.length < 1) {
      return;
    }
    setExtractorSettings(prev => ({
      ...prev,
      newImage: files[0],
    }));
  }, []);

  const onRemoveImage = useCallback(() => {
    setExtractorSettings(prev => ({
      ...prev,
      newImage: null,
    }));
  }, []);

  const imagePreviewUrl = useMemo((): string | null => {
    if (extractorSettings.newImage == null) {
      return null;
    }
    return URL.createObjectURL(extractorSettings.newImage);
  }, [extractorSettings.newImage]);

  // Post Processing Script (Form or FormGroup)
  const onChangePostProcessingScript = useCallback((script: string) => {
    setExtractorSettings(prev => ({
      ...prev,
      postProcessingScript: script,
    }));
  }, []);

  const onChangeTransformResponseScript = useCallback((script: string) => {
    setExtractorSettings(prev => ({
      ...prev,
      transformResponseScript: script,
    }));
  }, []);

  const onChangePreserveHorizonalWhiteSpace = useCallback((value: boolean) => {
    setExtractorSettings(prev => ({
      ...prev,
      preverseHorizontalWhiteSpace: value,
    }));
  }, []);

  const onChangePreserveVerticalWhiteSpace = useCallback((value: boolean) => {
    setExtractorSettings(prev => ({
      ...prev,
      preverseVerticalWhiteSpace: value,
    }));
  }, []);

  const onChangeSplitPromptForLineItems = useCallback((value: boolean) => {
    setExtractorSettings(prev => ({
      ...prev,
      splitPromptForLineItems: value,
    }));
  }, []);

  // Save
  const isFormDirty =
    isFormChanged ||
    !deepEqual(extractorSettings, initExtractorSettings.current);
  const isFormValid = !isExtractorNameEmpty;
  const [isSaving, setIsSaving] = useState(false);
  const onSaveFormSettings = useCallback(async () => {
    if (!isFormDirty || !isFormValid) {
      return;
    }
    setIsSaving(true);
    await onSave(extractorSettings);
    const dataLogDisabled =
      initExtractorSettings.current?.extractionRetentionEnabled === true &&
      extractorSettings.extractionRetentionEnabled === false;
    initExtractorSettings.current = extractorSettings;
    setIsSaving(false);

    if (dataLogDisabled) {
      dataLogSettingHandle.openDataLogDisabledWarningModal();
      dataLogSettingHandle.setDataLogDisableInstructionVisible(true);
    } else {
      dataLogSettingHandle.setDataLogDisableInstructionVisible(false);
    }
  }, [
    extractorSettings,
    isFormDirty,
    isFormValid,
    onSave,
    dataLogSettingHandle,
  ]);

  const onOCREngineChange = useCallback(
    (engine?: OCRConfigEngine) => {
      if (
        extractorSettings === undefined ||
        extractorSettings.ocrConfig?.engine === engine
      )
        return;

      const api =
        engine === "google"
          ? GOOGLE_ENGINE_API_OPTIONS[0].label
          : engine === "azure"
          ? AZURE_ENGINE_API_OPTIONS[1].label
          : undefined;

      setExtractorSettings(prev => ({
        ...prev,
        ocrConfig: {
          engine,
          api,
        },
      }));
    },
    [extractorSettings]
  );

  const onSelectedDocumentProcessingOptionChange = useCallback(
    (mode: ProcessingMode) => {
      if (
        extractorSettings === undefined ||
        extractorSettings.processingMode === mode
      )
        return;

      setExtractorSettings(prev => ({
        ...prev,
        processingMode: mode,
      }));
    },
    [extractorSettings]
  );

  const onSelectedLLMModelOptionChange = useCallback(
    (model: LLMModel) => {
      if (
        extractorSettings === undefined ||
        extractorSettings.llmModelInUse === model
      )
        return;

      setExtractorSettings(prev => ({
        ...prev,
        llmModelInUse: model,
      }));
    },
    [extractorSettings]
  );

  const onImageQualityEnabledChange = useCallback(
    (value: boolean) => {
      if (
        extractorSettings === undefined ||
        extractorSettings.imageQualityEnabled === value
      )
        return;

      setExtractorSettings(prev => ({
        ...prev,
        imageQualityEnabled: value,
      }));
    },
    [extractorSettings]
  );

  const onDataLogEnabledChange = useCallback(
    (value: boolean) => {
      if (
        extractorSettings === undefined ||
        extractorSettings.extractionRetentionEnabled === value
      )
        return;

      setExtractorSettings(prev => ({
        ...prev,
        extractionRetentionPeriod:
          prev.extractionRetentionPeriod ?? DEFAULT_DATA_LOG_RETENTION_PERIOD,
        extractionRetentionEnabled: value,
      }));
    },
    [extractorSettings]
  );

  const onDataLogRetentionPeriodChange = useCallback(
    (value: number | undefined) => {
      if (extractorSettings === undefined) return;

      setExtractorSettings(prev => ({
        ...prev,
        extractionRetentionPeriod: value,
      }));
    },
    [extractorSettings]
  );

  return useMemo(
    () => ({
      extractorSettings,
      hasPermissionToEdit: hasPermissionToEditResource,
      onExtractorNameChange: onExtractorNameChange,
      extractorNameErrorTextId: isExtractorNameEmpty
        ? "extractor.rename.error.empty_name"
        : null,
      onSelectImage,
      onRemoveImage,
      imagePreviewUrl,
      onChangePostProcessingScript,
      onChangeTransformResponseScript,
      onClickSave: onSaveFormSettings,
      isSaveDisabled: isSaving || !isFormValid || !isFormDirty || hasError,
      isSaving: isSaving,
      onOCREngineChange,
      isFormDirty,
      onSelectedDocumentProcessingOptionChange,
      onSelectedLLMModelOptionChange,

      onChangePreserveHorizonalWhiteSpace,
      onChangePreserveVerticalWhiteSpace,
      onChangeSplitPromptForLineItems,
      onImageQualityEnabledChange,
      setExtractorSettings,
      onDataLogEnabledChange,
      onDataLogRetentionPeriodChange,
      setHasError,
      dataLogSettingHandle,
    }),
    [
      extractorSettings,
      hasPermissionToEditResource,
      imagePreviewUrl,
      isExtractorNameEmpty,
      isFormDirty,
      isFormValid,
      isSaving,
      onChangePostProcessingScript,
      onChangeTransformResponseScript,
      onExtractorNameChange,
      onOCREngineChange,
      onRemoveImage,
      onSaveFormSettings,
      onSelectImage,
      onSelectedDocumentProcessingOptionChange,
      onSelectedLLMModelOptionChange,
      onChangePreserveHorizonalWhiteSpace,
      onChangePreserveVerticalWhiteSpace,
      onChangeSplitPromptForLineItems,
      onImageQualityEnabledChange,
      setExtractorSettings,
      onDataLogEnabledChange,
      onDataLogRetentionPeriodChange,
      hasError,
      setHasError,
      dataLogSettingHandle,
    ]
  );
}

export function useExtractorSettingSection(args: ExtractorSettingSectionProps) {
  const {
    extractor,
    workspaces,
    showWorkspaceSetups,
    useHandleReturns,
    isImportingForm,
    onClickImportForm,
    onClickEditPostProcessingScript,
    onClickEditTransformResponseScript,
    isExportingForm,
    onClickExportForm,
    formImageProps,
    allowedProcessingModes,
    allowedLLMModels,
    isCustomModel,
    importDisabled,
    children,
  } = args;
  const {
    onExtractorNameChange,
    onClickSave,
    onOCREngineChange,
    onSelectedDocumentProcessingOptionChange,
    onSelectedLLMModelOptionChange,
  } = useHandleReturns;

  const displayExtractorId = args.displayExtractorId ?? extractor.id;

  const onCreateExtractorWorkspaceClick = React.useCallback(() => {
    args.onCreateExtractorWorkspaceClick(extractor);
  }, [args, extractor]);
  const _onExtractorNameChange = useCallback(
    (
      ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      ev.preventDefault();
      ev.stopPropagation();
      onExtractorNameChange(newValue ?? "");
    },
    [onExtractorNameChange]
  );
  const _onClickSave = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      onClickSave();
    },
    [onClickSave]
  );

  const isFeatureEnabled = useSelector((state: RootState) =>
    state.resourceOwner.isFeatureEnabled()
  );

  const _onOCREngineChange = useCallback(
    (
      _e: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (!option) {
        return;
      }
      const engine = option.key ? (option.key as OCRConfigEngine) : undefined;
      onOCREngineChange(engine);
    },
    [onOCREngineChange]
  );

  const onChangePreserveHorizonalWhiteSpace = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      if (checked !== undefined) {
        useHandleReturns.onChangePreserveHorizonalWhiteSpace(checked);
      }
    },
    [useHandleReturns]
  );

  const onChangePreserveVerticalWhiteSpace = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      if (checked !== undefined) {
        useHandleReturns.onChangePreserveVerticalWhiteSpace(checked);
      }
    },
    [useHandleReturns]
  );

  const onChangeSplitPromptForLineItems = useCallback(
    (
      _event?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ) => {
      if (checked !== undefined) {
        useHandleReturns.onChangeSplitPromptForLineItems(checked);
      }
    },
    [useHandleReturns]
  );

  const availableEngines = useAvailableEngines();

  const { localized } = useLocale();

  const engineOptions = useMemo(
    () => [
      {
        key: "",
        text: localized("extractor_setting.specify_ocr_engine.unspecified"),
      },
      ...availableEngines.map(engine => ({
        key: engine,
        text: localized(`ocr_config.engine.${engine}`),
      })),
    ],
    [availableEngines, localized]
  );

  const documentProcessingOptions = React.useMemo<IChoiceGroupOption[]>(
    () =>
      Object.values(ProcessingMode).map(mode => ({
        disabled: !allowedProcessingModes.includes(mode),
        key: mode,
        text: localized(
          `extractor_setting.document_processing_option.option.${mode}.label`
        ),
        onRenderLabel: props =>
          props !== undefined ? (
            <ProcessingModeChoiceGroupOptionLabel
              text={props.text}
              description={localized(
                `extractor_setting.document_processing_option.option.${mode}.description`
              )}
              disabled={!allowedProcessingModes.includes(mode)}
            />
          ) : null,
      })),
    [localized, allowedProcessingModes]
  );

  const _onSelectedDocumentProcessingOptionChange = useCallback(
    (
      _e?: React.FormEvent<HTMLInputElement | HTMLElement>,
      option?: IChoiceGroupOption
    ) => {
      if (option && option.key && onSelectedDocumentProcessingOptionChange) {
        onSelectedDocumentProcessingOptionChange(option.key as ProcessingMode);
      }
    },
    [onSelectedDocumentProcessingOptionChange]
  );

  const llmModelOptions = React.useMemo(
    () =>
      allowedLLMModels?.map(x => ({
        key: x,
        text: localized(`extractor_setting.llm_model_option.option.${x}.label`),
      })),
    [localized, allowedLLMModels]
  );

  const _onSelectedLLMModelOptionChange = useCallback(
    (
      _e: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ) => {
      if (option && option.key && onSelectedLLMModelOptionChange) {
        onSelectedLLMModelOptionChange(option.key as LLMModel);
      }
    },
    [onSelectedLLMModelOptionChange]
  );

  const isAdvancedPromptTuningEnabled = useAppSelector(state =>
    state.resourceOwner.isFeatureEnabled.apply(state.resourceOwner)(
      UserFeatureFlag.AdvancedPromptTuning
    )
  );

  const isImageQualitySettingVisible = isFeatureEnabled(
    UserFeatureFlag.ImageQuality
  );

  const isPromptTuningSettingVisible =
    isAdvancedPromptTuningEnabled &&
    llmModelOptions &&
    llmModelOptions.length > 0;

  return React.useMemo(
    () => ({
      workspaces,
      showWorkspaceSetups,
      extractorId: extractor.id,
      extractorType: extractor.extractorType,
      onCreateExtractorWorkspaceClick,
      ...useHandleReturns,
      onExtractorNameChange: _onExtractorNameChange,
      onOCREngineChange: _onOCREngineChange,
      onClickSave: _onClickSave,
      canEditPostProcessingScript: isFeatureEnabled(
        UserFeatureFlag.PostProcessScript
      ),
      canEditTransformResponseScript: isFeatureEnabled(
        UserFeatureFlag.TransformResponseScript
      ),
      onClickEditPostProcessingScript,
      onClickEditTransformResponseScript,
      canImportForm: isImportingForm != null && onClickImportForm != null,
      isImportingForm,
      onClickImportForm,
      canExportForm: isExportingForm != null && onClickExportForm != null,
      isExportingForm,
      onClickExportForm,
      formImageProps,
      engineOptions,
      displayExtractorId,
      documentProcessingOptions,
      onSelectedDocumentProcessingOptionChange:
        _onSelectedDocumentProcessingOptionChange,
      llmModelOptions,
      onSelectedLLMModelOptionChange: _onSelectedLLMModelOptionChange,
      isCustomModel,
      importDisabled,
      isPromptTuningSettingVisible,
      isImageQualitySettingVisible,
      onChangePreserveHorizonalWhiteSpace,
      onChangePreserveVerticalWhiteSpace,
      onChangeSplitPromptForLineItems,
      children,
      dataLogSettingHandle: useHandleReturns.dataLogSettingHandle,
    }),
    [
      _onClickSave,
      _onExtractorNameChange,
      _onOCREngineChange,
      extractor.extractorType,
      extractor.id,
      formImageProps,
      isExportingForm,
      isFeatureEnabled,
      isImportingForm,
      onClickEditPostProcessingScript,
      onClickEditTransformResponseScript,
      onClickExportForm,
      onClickImportForm,
      onCreateExtractorWorkspaceClick,
      showWorkspaceSetups,
      useHandleReturns,
      workspaces,
      engineOptions,
      displayExtractorId,
      documentProcessingOptions,
      _onSelectedDocumentProcessingOptionChange,
      llmModelOptions,
      _onSelectedLLMModelOptionChange,
      isCustomModel,
      importDisabled,
      isPromptTuningSettingVisible,
      isImageQualitySettingVisible,
      onChangePreserveHorizonalWhiteSpace,
      onChangePreserveVerticalWhiteSpace,
      onChangeSplitPromptForLineItems,
      children,
    ]
  );
}

export function PromptTuningSetting(
  props: ReturnType<typeof useExtractorSettingSection>
) {
  const { localized } = useLocale();

  const selectLLModelOption =
    props.llmModelOptions && props.llmModelOptions.length > 0
      ? props.extractorSettings.llmModelInUse || props.llmModelOptions[0].key
      : undefined;

  return (
    <div className={cn(styles["block"])}>
      <div className={cntl`${styles["block-content"]} gap-[10px]`}>
        <div className={cn(styles["block-title"])}>
          <FormattedMessage id="extractor.setting.block.prompt_tuning_settings.title" />
        </div>
        <div className={`flex flex-col gap-[12px]`}>
          <div className={styles["advanced-settings__field"]}>
            <Dropdown
              selectedKey={selectLLModelOption}
              label={localized(
                "extractor.setting.block.prompt_tuning_settings.field.llm_model.label"
              )}
              options={props.llmModelOptions ?? []}
              onChange={props.onSelectedLLMModelOptionChange}
              placeholder={localized("dropdown.placeholder")}
              disabled={!props.hasPermissionToEdit}
            />
          </div>

          <div className={"flex flex-col gap-[5px]"}>
            <Label>
              <FormattedMessage id="extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.label" />
            </Label>
            <Checkbox
              label={localized(
                "extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.options.horizontal"
              )}
              checked={
                props.extractorSettings.preverseHorizontalWhiteSpace ?? false
              }
              onChange={props.onChangePreserveHorizonalWhiteSpace}
            />
            <Checkbox
              label={localized(
                "extractor.setting.block.prompt_tuning_settings.field.white_spec_preservation.options.vertical"
              )}
              onChange={props.onChangePreserveVerticalWhiteSpace}
              checked={
                props.extractorSettings.preverseVerticalWhiteSpace ?? false
              }
            />
          </div>

          <div className={"flex flex-col gap-[5px]"}>
            <Label>
              <FormattedMessage id="extractor.setting.block.prompt_tuning_settings.field.prompt_splitting.label" />
            </Label>

            <Checkbox
              label={localized(
                "extractor.setting.block.prompt_tuning_settings.field.prompt_splitting.option"
              )}
              onChange={props.onChangeSplitPromptForLineItems}
              checked={props.extractorSettings.splitPromptForLineItems ?? true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function AdvancedSetting(
  props: ReturnType<typeof useExtractorSettingSection>
) {
  const { localized } = useLocale();

  return (
    <div
      className={cn(styles["block"], styles["advanced-settings__container"])}
    >
      <div
        className={cn(
          styles["block-content"],
          styles["advanced-settings__content"]
        )}
      >
        <div
          className={cn(
            styles["block-title"],
            styles["advanced-settings__title"]
          )}
        >
          <FormattedMessage id="extractor.setting.block.advanced_settings.title" />
        </div>
        <div className={styles["advanced-settings__content"]}>
          <div className={styles["advanced-settings__fields"]}>
            <div className={styles["advanced-settings__field"]}>
              <Dropdown
                selectedKey={props.extractorSettings.ocrConfig?.engine || ""}
                label={localized("extractor_setting.specify_ocr_engine.title")}
                options={props.engineOptions}
                onChange={props.onOCREngineChange}
                placeholder={localized("dropdown.placeholder")}
                disabled={!props.hasPermissionToEdit}
              />
            </div>

            {props.canEditPostProcessingScript ? (
              <div className={styles["advanced-settings__field"]}>
                <Label className={styles["advanced-settings__field-label"]}>
                  <FormattedMessage id="extractor.setting.block.advanced_settings.field.post_processing_script.label" />
                  <BetaTag />
                </Label>
                <DefaultButton
                  textId={"extractor_setting.edit_post_process_script"}
                  onClick={props.onClickEditPostProcessingScript}
                />
              </div>
            ) : null}

            {props.canEditTransformResponseScript ? (
              <div className={styles["advanced-settings__field"]}>
                <Label className={styles["advanced-settings__field-label"]}>
                  <FormattedMessage id="extractor.setting.block.advanced_settings.field.transform_response_script.label" />
                  <BetaTag />
                </Label>
                <DefaultButton
                  textId={"extractor_setting.edit_transform_response_script"}
                  onClick={props.onClickEditTransformResponseScript}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ImageQualitySetting(
  props: ReturnType<typeof useExtractorSettingSection>
) {
  const { onImageQualityEnabledChange } = props;

  const onToggleChange = useCallback(
    (_e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      onImageQualityEnabledChange(checked ?? false);
    },
    [onImageQualityEnabledChange]
  );

  return (
    <div className={styles["block"]}>
      <div className={cntl`${styles["block-content"]} gap-[10px]`}>
        <div
          className={cntl`${styles["block-title"]} flex flex-row gap-[10px]`}
        >
          <Toggle
            checked={props.extractorSettings.imageQualityEnabled ?? false}
            onChange={onToggleChange}
          />
          <FormattedMessage id="extractor.setting.block.image_quality_check.title" />
          <div className="flex items-start">
            <BetaTag />
          </div>
        </div>

        <div
          className={cntl`${styles["subtitle"]}
            pb-[4px]`}
        >
          <div>
            <FormattedMessage id="extractor.setting.block.image_quality_check.description" />
          </div>
        </div>
      </div>
    </div>
  );
}

export function AdvancedPatternMatchingSetting(props: {
  extractorSettings: ExtractorSettings;
  setExtractorSettings: React.Dispatch<React.SetStateAction<ExtractorSettings>>;
  onSetupClick: () => void;
}) {
  const { setExtractorSettings } = props;

  const onToggleChange = useCallback(
    (_e: React.MouseEvent<HTMLElement>, checked?: boolean) => {
      setExtractorSettings(prev => ({
        ...prev,
        advancedPatternMatchingEnabled: checked ?? false,
      }));
    },
    [setExtractorSettings]
  );

  return (
    <div className={cn(styles["block"])}>
      <div className={cntl`${styles["block-content"]} gap-[10px]`}>
        <div
          className={cntl`${styles["block-title"]} flex flex-row gap-[10px]`}
        >
          <Toggle
            checked={props.extractorSettings.advancedPatternMatchingEnabled}
            onChange={onToggleChange}
          />
          <FormattedMessage id="advance_token_setup_launcher.title" />
        </div>

        <div
          className={cntl`${styles["subtitle"]}
            pb-[4px]`}
        >
          <div>
            <FormattedMessage id="advance_token_setup_launcher.description" />
          </div>
        </div>

        <div>
          <DefaultButton
            textId="advance_token_setup_launcher.setup_button"
            styles={{
              root: {
                padding: 8,
              },
              flexContainer: {
                flexDirection: "row-reverse",
              },
            }}
            iconProps={{ iconName: "Forward" }}
            onClick={props.onSetupClick}
          />
        </div>
      </div>
    </div>
  );
}

export function ExtractorSettingSectionImpl(
  props: ReturnType<typeof useExtractorSettingSection>
) {
  const { localized } = useLocale();

  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <div
          className={cn(styles["block"], styles["general-settings__container"])}
        >
          <div
            className={cn(
              styles["block-content"],
              styles["general-settings__content"]
            )}
          >
            <div
              className={cn(
                styles["block-title"],
                styles["general-settings__title"]
              )}
            >
              <FormattedMessage id="extractor.setting.block.general_settings.title" />
            </div>
            <div className={styles["general-settings__content"]}>
              <div className={styles["general-settings__fields"]}>
                <div className={styles["general-settings__field"]}>
                  <Label className={styles["general-settings__field-label"]}>
                    <FormattedMessage id="extractor.setting.block.general_settings.field.extractor_name.label" />
                  </Label>
                  <TextField
                    value={props.extractorSettings.extractorName}
                    onChange={props.onExtractorNameChange}
                    errorMessage={
                      props.extractorNameErrorTextId != null
                        ? localized(props.extractorNameErrorTextId)
                        : undefined
                    }
                  />
                </div>
                <div className={styles["general-settings__field"]}>
                  <Label className={styles["general-settings__field-label"]}>
                    <FormattedMessage id="extractor.setting.block.general_settings.field.extractor_id.label" />
                  </Label>
                  <CopyBlock content={props.displayExtractorId} />
                </div>
                <div className={styles["general-settings__field"]}>
                  <Label className={styles["general-settings__field-label"]}>
                    <FormattedMessage id="extractor.setting.block.general_settings.field.extractor_type.label" />
                  </Label>
                  <TextField
                    className={styles["general-settings__extractor-type"]}
                    defaultValue={
                      props.extractorType != null
                        ? localized(
                            mapExtractorTypeToMessageId(props.extractorType)
                          )
                        : undefined
                    }
                    disabled
                  />
                </div>

                <div
                  className={
                    styles["general-settings__document_processing_option"]
                  }
                >
                  <Label className={styles["general-settings__field-label"]}>
                    <FormattedMessage id="extractor.setting.block.general_settings.field.document_processing_option.label" />
                  </Label>
                  <ChoiceGroup
                    options={props.documentProcessingOptions}
                    selectedKey={props.extractorSettings.processingMode ?? null}
                    onChange={props.onSelectedDocumentProcessingOptionChange}
                  />
                </div>

                {props.formImageProps != null ? (
                  <div className={styles["general-settings__field"]}>
                    <Label className={styles["general-settings__field-label"]}>
                      <FormattedMessage
                        id={
                          props.formImageProps?.imageType === "thumbnail"
                            ? "extractor_setting.thumbnail_image"
                            : "extractor_setting.replace_master_image"
                        }
                      />
                    </Label>
                    {props.imagePreviewUrl != null &&
                    props.imagePreviewUrl.length > 0 ? (
                      <Img
                        frameClass={styles["image-frame"]}
                        src={props.imagePreviewUrl}
                      />
                    ) : props.extractorSettings.newImage !== null && // remove image
                      props.formImageProps.imageSrc != null &&
                      props.formImageProps.imageSrc.length > 0 ? (
                      <Img
                        frameClass={styles["image-frame"]}
                        src={props.formImageProps.imageSrc}
                      />
                    ) : null}
                    <div className={styles["upload-button-wrapper"]}>
                      <FileDefaultButton
                        textId={
                          props.formImageProps?.imageType === "thumbnail"
                            ? "extractor_setting.replace_thumbnail"
                            : "extractor_setting.choose_a_file"
                        }
                        onFiles={props.onSelectImage}
                        iconProps={{ iconName: "Upload" }}
                        styles={{
                          flexContainer: {
                            flexDirection: "row-reverse",
                          },
                        }}
                      />

                      {(props.imagePreviewUrl != null &&
                        props.imagePreviewUrl.length > 0) ||
                      (props.extractorSettings.newImage !== null && // remove image
                        props.formImageProps.imageSrc != null &&
                        props.formImageProps.imageSrc.length > 0) ? (
                        <IconButton
                          className={styles["remove-image-button"]}
                          iconProps={{ iconName: "Delete" }}
                          onClick={props.onRemoveImage}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {props.canImportForm || props.canExportForm ? (
                  <ImportExportSetting
                    canImport={props.canImportForm}
                    canExport={props.canExportForm}
                    isImporting={props.isImportingForm}
                    isExporting={props.isExportingForm}
                    onClickImport={props.onClickImportForm}
                    onClickExport={props.onClickExportForm}
                    hasPermissionToEdit={props.hasPermissionToEdit}
                    isCustomModel={props.isCustomModel}
                    importDisabled={props.importDisabled}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <AdvancedSetting {...props} />
        {props.isImageQualitySettingVisible && (
          <ImageQualitySetting {...props} />
        )}

        {(props.dataLogSettingHandle.isDataLogRequestable ||
          props.dataLogSettingHandle.isDataLogWorkable) && (
          <DataLogSetting {...props} />
        )}
        {props.children}

        {props.isPromptTuningSettingVisible && (
          <PromptTuningSetting {...props} />
        )}

        <div
          className={cn(
            styles["block"],
            styles["workspace-setups__container"],
            {
              [styles["hide"]]: !props.showWorkspaceSetups,
            }
          )}
        >
          <div
            className={cn(
              styles["block-content"],
              styles["workspace-setups__content"]
            )}
          >
            <div className={styles["workspace-setups__header"]}>
              <div className={cn(styles["workspace-setups__header-title"])}>
                <FormattedMessage id="extractor.setting.block.workspace_setups.title" />
                <BetaTag />
              </div>
              <div className={styles["workspace-setups__header-description"]}>
                <FormattedMessage id="extractor.setting.block.workspace_setups.description" />
              </div>
            </div>
            <div
              className={cn(
                styles["workspace-setups__connected_workspace"],
                styles["connected_workspace__container"]
              )}
            >
              <div
                className={cn(styles["connected_workspace__list"], {
                  [styles["hide"]]: props.workspaces.length === 0,
                })}
              >
                <div className={styles["connected_workspace__list-title"]}>
                  <FormattedMessage id="extractor.setting.block.workspace_setups.connected_workspace.title" />
                </div>
                <div className={styles["connected_workspace__list-items"]}>
                  {props.workspaces.map(workspace => (
                    <WorkspaceItem
                      key={workspace.id}
                      className={styles["connected_workspace__list-item"]}
                      workspace={workspace}
                    />
                  ))}
                </div>
              </div>
              <div className={styles["connected_workspace__buttons"]}>
                <DefaultButton
                  textId="extractor.setting.block.workspace_setups.connected_workspace.label.create_workspace_for_extractor"
                  iconName="Forward"
                  reverseIconPosition={true}
                  onClick={props.onCreateExtractorWorkspaceClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={cn(styles["block"], styles["contact-us__container"])}>
          <div
            className={cn(
              styles["block-content"],
              styles["contact-us__content"]
            )}
          >
            <div
              className={cn(styles["block-title"], styles["contact-us__title"])}
            >
              <FormattedMessage id="extractor.setting.block.contact_us.title" />
            </div>
            <div className={styles["contact-us__description"]}>
              <FormattedMessage
                id="extractor.setting.block.contact_us.description"
                values={{
                  url: (
                    <Link href="https://www.formx.ai/talk-with-us">
                      https://www.formx.ai/talk-with-us
                    </Link>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footer__container"]}>
        <div className={styles["footer__separator"]}></div>
        <div className={styles["footer__buttons"]}>
          <DangerButton
            textId="common.save"
            onClick={props.onClickSave}
            disabled={props.isSaveDisabled}
          />
        </div>
      </div>
    </div>
  );
}

export function ExtractorSettingSection(args: ExtractorSettingSectionProps) {
  const props = useExtractorSettingSection(args);
  return <ExtractorSettingSectionImpl {...props} />;
}
