import {
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDialogContentProps,
  IDropdownOption,
  Text,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { useWorkerToken } from "../../hooks/app";
import { CopyBlock } from "../CopyBlock";
import { CopyButton } from "../CopyButton";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";

export function useZapierModalHandle(workspaceId: string) {
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const [callback, setCallback] = React.useState<() => void | undefined>();

  const { token, tokens, onSelectToken } = useWorkerToken();

  const open = useCallback(async (): Promise<void> => {
    setIsDialogOpen(true);

    return new Promise(resolve => {
      setCallback(() => resolve);
    });
  }, [setIsDialogOpen, setCallback]);

  const close = useCallback(() => {
    setIsDialogOpen(false);
    callback?.();
  }, [setIsDialogOpen, callback]);

  const props = useMemo(() => {
    return {
      isDialogOpen,
      close,
      workspaceId,
      token,
      tokens,
      onSelectToken,
    };
  }, [isDialogOpen, close, workspaceId, token, tokens, onSelectToken]);

  const methods = useMemo(
    () => ({
      open,
    }),
    [open]
  );

  return useMemo(() => {
    return {
      props,
      methods,
    };
  }, [props, methods]);
}

export function ZapierModal(
  props: ReturnType<typeof useZapierModalHandle>["props"]
) {
  const { isDialogOpen, close, workspaceId, token, tokens, onSelectToken } =
    props;

  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.close,
      title: localized("workspace.integrations.modal.export_zapier.title"),
    }),
    [localized]
  );

  const options = tokens
    ? tokens.map(t => ({
        key: t.token,
        text: t.name,
      }))
    : [];

  const onDropdownChange = useCallback(
    (
      _event: React.FormEvent<unknown>,
      option?: IDropdownOption,
      _n?: number
    ) => {
      if (option && onSelectToken) {
        onSelectToken(option.key as string);
      }
    },
    [onSelectToken]
  );

  const onClickGetStarted = useCallback(() => {
    window.open("https://zapier.com", "_blank");
  }, []);

  return (
    <Dialog
      minWidth={494}
      dialogContentProps={dialogContentProps}
      hidden={!isDialogOpen}
      onDismiss={close}
    >
      <div className="mb-6">
        <FormattedMessage id="workspace.integrations.modal.export_zapier.description" />
      </div>
      <div className="mb-3 text-sm font-semibold">
        <FormattedMessage id="workspace.integrations.modal.export_zapier.workspace_id" />
      </div>
      <div className="mb-6">
        <CopyBlock textClassName="!text-[#a19f9d]" content={workspaceId} />
      </div>
      <div className="mb-3 text-sm font-semibold">
        <FormattedMessage id="workspace.integrations.modal.export_zapier.access_token" />
      </div>
      {token ? (
        <div className="flex flex-row align items-center">
          <Dropdown
            selectedKey={token}
            className="mr-1 w-[112]"
            options={options}
            onChange={onDropdownChange}
            styles={{
              title: "h-[39px] leading-[39px] bg-[#faf9f8] border-0",
              dropdown: "bg-[#faf9f8] focus:after:border-0",
              caretDownWrapper: "leading-[39px]",
              caretDown: "text-[#605E5C]",
            }}
          />
          <div className="flex flex-row align items-center grow bg-[#faf9f8] px-2 py-1">
            <Text className="grow text-[#a19f9d]">
              ***********************************
            </Text>
            <CopyButton
              className="pt-[5px] pr-[6px] pb-[6px] pl-[2px] text-xs"
              text={token}
              showText={true}
            />
          </div>
        </div>
      ) : null}
      <DialogFooter className="mt-9">
        <PrimaryButton
          onClick={onClickGetStarted}
          textId={"workspace.integrations.modal.export_zapier.get_started"}
        />
      </DialogFooter>
    </Dialog>
  );
}
