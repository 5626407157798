import {
  PaginatedWithOffsetDataLog,
  paginatedWithOffsetDataLogSchema,
} from "../../types/dataLog";
import { ApiClientConstructor, _BaseApiClient } from "../base";

export type DataLogExtractorType = "form" | "form_group";

type ListDataLogArgs = {
  resourceOwnerId: string;
  formOrFormGroupId: string;
  size: number;
  cursor?: string;
};

type CreateDataRemovalRequestArgs = {
  resourceOwnerId: string;
  formOrFormGroupId: string;
};

export interface DataLogApiClient {
  listDataLog: (args: ListDataLogArgs) => Promise<PaginatedWithOffsetDataLog>;
  createDataLogRemovalRequest: (
    args: CreateDataRemovalRequestArgs
  ) => Promise<void>;
}

export function withDataLogApi<
  TBase extends ApiClientConstructor<_BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async listDataLog(
      args: ListDataLogArgs
    ): Promise<PaginatedWithOffsetDataLog> {
      return this.lambda(
        "data_log:list",
        {
          resource_owner_id: args.resourceOwnerId,
          form_or_form_group_id: args.formOrFormGroupId,
          page_args: {
            size: args.size,
            cursor: args.cursor ?? "",
          },
        },
        paginatedWithOffsetDataLogSchema
      );
    }

    async createDataLogRemovalRequest(
      args: CreateDataRemovalRequestArgs
    ): Promise<void> {
      await this.lambda("data_log:create-removal-request", {
        resource_owner_id: args.resourceOwnerId,
        form_or_form_group_id: args.formOrFormGroupId,
      });
    }
  };
}
