import { CheckboxVisibility, DetailsList, IColumn } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";

import { useLocale } from "../../contexts/locale";
import { WorkspaceIntegrationConfiguration } from "../../types/workspaceIntegration";
import { ActionButton } from "../WrappedMSComponents/Buttons";

interface WorkspaceWebhookListProps {
  configurations: WorkspaceIntegrationConfiguration[];
  onConfigurationRemoved: (
    configuration: WorkspaceIntegrationConfiguration
  ) => void;
}

export function WorkspaceWebhookListImpl(props: WorkspaceWebhookListProps) {
  const { configurations, onConfigurationRemoved } = props;
  const { localized } = useLocale();

  const _onConfigurationRemoved = useCallback(
    (configuration: WorkspaceIntegrationConfiguration) => {
      return () => {
        onConfigurationRemoved(configuration);
      };
    },
    [onConfigurationRemoved]
  );

  const columns = useMemo<IColumn[]>(() => {
    return [
      {
        key: "connectionId",
        name: localized(
          "workspace.integrations.configuration_list.column.connectionId"
        ),
        minWidth: 100,
      },
      {
        key: "status",
        name: localized(
          "workspace.integrations.configuration_list.column.status"
        ),
        minWidth: 100,
      },
      {
        key: "action",
        name: localized(
          "workspace.integrations.configuration_list.column.action"
        ),
        minWidth: 100,
      },
    ];
  }, [localized]);

  const onRenderItemColumn = useCallback(
    (
      item?: WorkspaceIntegrationConfiguration,
      _index?: number,
      column?: IColumn
    ) => {
      if (item?.optionType !== "export_zapier") {
        return null;
      }

      switch (column?.key) {
        case "connectionId":
          return (
            <div className="flex items-center h-full">
              <span className="text-sm text-[#201F1E]">{item.zapId}</span>
            </div>
          );
        case "status":
          return (
            <div className="flex items-center h-full">
              <span className="text-sm text-[#201F1E]">
                {localized(
                  "workspace.integrations.configuration_list.status.connected"
                )}
              </span>
            </div>
          );
        case "action":
          return (
            <ActionButton
              className="p-0"
              textId="workspace.integrations.configuration_list.action.remove"
              isDestroy={true}
              styles={{
                label: { margin: 0 },
              }}
              onClick={_onConfigurationRemoved(item)}
            />
          );
        default:
          return null;
      }
    },
    [_onConfigurationRemoved, localized]
  );

  return (
    <DetailsList
      items={configurations}
      onRenderItemColumn={onRenderItemColumn}
      columns={columns}
      checkboxVisibility={CheckboxVisibility.hidden}
    />
  );
}

export const WorkspaceWebhookList = React.memo(WorkspaceWebhookListImpl);
