import { Icon } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import * as React from "react";

import { useLocale } from "../../contexts/locale";
import { WorkspaceIntegrationOptionType } from "../../types/workspaceIntegration";
import styles from "./styles.module.scss";

const iconIdByOptionType: Record<WorkspaceIntegrationOptionType, string> = {
  import_sharepoint: "IconSharePoint",
  import_zapier: "IconZapier",
  import_email: "IconEmail",
  import_googleDrive: "IconGoogleDrive",
  import_dropbox: "IconDropbox",
  export_zapier: "IconZapier",
  export_webhook: "IconWebhook",
};

const titleIdByOptionType: Record<WorkspaceIntegrationOptionType, string> = {
  import_sharepoint: "workspace.integrations.option.import_sharepoint.title",
  import_zapier: "workspace.integrations.option.import_zapier.title",
  import_email: "workspace.integrations.option.import_email.title",
  import_googleDrive: "workspace.integrations.option.import_googleDrive.title",
  import_dropbox: "workspace.integrations.option.import_dropbox.title",
  export_zapier: "workspace.integrations.option.export_zapier.title",
  export_webhook: "workspace.integrations.option.export_webhook.title",
};

const descIdByOptionType: Record<WorkspaceIntegrationOptionType, string> = {
  import_sharepoint:
    "workspace.integrations.option.import_sharepoint.description",
  import_zapier: "workspace.integrations.option.import_zapier.description",
  import_email: "workspace.integrations.option.import_email.description",
  import_googleDrive:
    "workspace.integrations.option.import_googleDrive.description",
  import_dropbox: "workspace.integrations.option.import_dropbox.description",
  export_zapier: "workspace.integrations.option.export_zapier.description",
  export_webhook: "workspace.integrations.option.export_webhook.description",
};

interface Props {
  optionType: WorkspaceIntegrationOptionType;
  onOptionClick: (op: WorkspaceIntegrationOptionType) => void;
  isAdded: boolean;
  isCommingSoon: boolean;
}

function WorkspaceIntegrationOptionImpl(props: Props) {
  const { optionType, isAdded, isCommingSoon, onOptionClick } = props;
  const { localized } = useLocale();

  const onClick = React.useCallback(() => {
    onOptionClick(optionType);
  }, [optionType, onOptionClick]);

  const clickable = !isAdded && !isCommingSoon;

  return (
    <div
      className={cn(styles["container"], {
        [styles["clickable"]]: clickable,
        [styles["disabled"]]: isCommingSoon,
      })}
      role="button"
      onClick={clickable ? onClick : undefined}
    >
      <div className={styles["icon-container"]}>
        <Icon
          className={styles["icon"]}
          iconName={iconIdByOptionType[optionType]}
        />
      </div>
      <div className={cn(styles["info"])}>
        <h3 className={styles["heading"]}>
          <FormattedMessage id={titleIdByOptionType[optionType]} />
          {isCommingSoon && (
            <>
              {` (${localized("workspace.integrations.option.comming_soon")})`}
            </>
          )}
        </h3>
        <p className={styles["desc"]}>
          <FormattedMessage id={descIdByOptionType[optionType]} />
        </p>
      </div>
      {!isCommingSoon && (
        <div className={styles["right"]}>
          {isAdded ? (
            <span className={styles["added-text"]}>
              <FormattedMessage id="workspace.integrations.option.added" />
            </span>
          ) : (
            <span className={styles["add-text"]}>
              <FormattedMessage id="workspace.integrations.option.add" />
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export const WorkspaceIntegrationOption = React.memo(
  WorkspaceIntegrationOptionImpl
);
