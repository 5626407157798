import * as React from "react";

import {
  AdvanceTokenSetupVersionHistoryModalBundleHandle,
  AdvanceTokenSetupVersionHistoryModalBundleProps,
  AdvanceTokenSetupVersionHistoryModalRequest,
} from "./type";

export function useAdvanceTokenSetupVersionHistoryModalHandle() {
  const handleRef = React.useRef<
    AdvanceTokenSetupVersionHistoryModalBundleHandle | undefined
  >();

  const open = React.useCallback(
    async (request: AdvanceTokenSetupVersionHistoryModalRequest) => {
      return handleRef.current?.open(request);
    },
    []
  );

  const triggerProps = React.useMemo(
    () => ({
      handleRef,
    }),
    []
  );

  return React.useMemo(
    () => ({
      open,
      triggerProps,
    }),
    [open, triggerProps]
  );
}

export function AdvanceTokenSetupVersionHistoryModal(
  props: ReturnType<
    typeof useAdvanceTokenSetupVersionHistoryModalHandle
  >["triggerProps"]
) {
  const [Bundle, setBundle] = React.useState<
    | React.ComponentType<AdvanceTokenSetupVersionHistoryModalBundleProps>
    | undefined
  >();

  React.useEffect(() => {
    // FormX is still using React 16 which doesn't support Suspense
    // May consider using React.lazy once the React version upgraded
    import("./bundle").then(module => {
      setBundle(() => module.AdvanceTokenSetupVersionHistoryModalBundle);
    });
  }, []);

  if (!Bundle) {
    return <></>;
  }

  return <Bundle {...props} />;
}
