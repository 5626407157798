import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import React, { ReactElement } from "react";

import styles from "./styles.module.scss";

interface Props {
  className?: string;
  textId?: string;
}

const BetaTag = (props: Props): ReactElement => {
  const { className } = props;

  const classes = cntl`
  ${styles["beta-tag"]}
  ${className}
  bg-[#ffd633]
  `;

  const textId = props.textId ?? "beta_tag.name";

  return (
    <div className={classes}>
      <FormattedMessage id={textId} />
    </div>
  );
};

export default BetaTag;
