import * as yup from "yup";

export type WorkspaceWebhookType = "formx" | "zapier" | "n8n";

export const workspaceWebhookSchema = yup
  .object({
    webhookId: yup.string().defined(),
    type: yup.mixed<WorkspaceWebhookType>().required(),
    config: yup.mixed(),
  })
  .camelCase();

export type WorkspaceWebhook = yup.InferType<typeof workspaceWebhookSchema>;

export const workspaceWebhookListSchema = yup
  .object({
    workspaceWebhooks: yup.array(workspaceWebhookSchema).required(),
  })
  .camelCase();

export type WorkspaceWebhookList = yup.InferType<
  typeof workspaceWebhookListSchema
>;
