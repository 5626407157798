import { createAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { useStore } from "react-redux";

import { apiClient } from "../apiClient";
import { useAppDispatch } from "../hooks/redux";
import { RootState } from "../redux/types";
import { WorkspaceWebhook } from "../types/workspaceWebhook";

export const StartedFetchingWorkspaceWebhookList = createAction(
  "webhook/startedFetchingWorkspaceWebhookList"
);

export const GotWorkspaceWebhookList = createAction<WorkspaceWebhook[]>(
  "webhook/gotWorkspaceWebhookList"
);

export function useWorkspaceWebhookActionCreator() {
  const dispatch = useAppDispatch();
  const { getState } = useStore<RootState>();

  const listWorkspaceWebhooks = useCallback(
    async (workspaceId: string) => {
      const state = getState();
      const { resourceOwnerId } = state.resourceOwner;

      if (!resourceOwnerId) {
        return;
      }

      dispatch(StartedFetchingWorkspaceWebhookList());

      const listResult = await apiClient.listWorkspaceWebhooks(
        resourceOwnerId,
        workspaceId
      );

      dispatch(GotWorkspaceWebhookList(listResult.workspaceWebhooks));
    },
    [dispatch, getState]
  );

  return useMemo(
    () => ({
      listWorkspaceWebhooks,
    }),
    [listWorkspaceWebhooks]
  );
}
