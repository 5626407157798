import * as React from "react";
import { useStore } from "react-redux";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { apiClient } from "../apiClient";
import { FOCRError } from "../errors";
import { RootState } from "../redux/types";
import { ConfirmModalType } from "../types/confirmation";
import { PaginatedWithOffsetDataLog } from "../types/dataLog";
import { ensureFOCRError } from "../utils/errors";
import { useToast } from "./toast";

export function useDataLogListResource() {
  const [formOrFormGroupId, setFormOrFormGroupId] = React.useState<
    string | undefined
  >();
  const [resource, setResource] = React.useState<
    PaginatedWithOffsetDataLog | undefined
  >();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [error, setError] = React.useState<FOCRError | undefined>();
  const { getState } = useStore<RootState>();

  const query = React.useCallback(
    async (formOrFormGroupId: string, size: number = 10, cursor?: string) => {
      const { resourceOwnerId } = getState().resourceOwner;

      setIsFetching(true);
      setFormOrFormGroupId(formOrFormGroupId);
      setError(undefined);
      let response, error;
      try {
        response = await apiClient.listDataLog({
          resourceOwnerId: resourceOwnerId ?? "",
          formOrFormGroupId,
          size,
          cursor,
        });
        setResource(response);
      } catch (e) {
        error = ensureFOCRError(e);
        setError(error);
      } finally {
        setIsFetching(false);
      }

      return { response, error };
    },
    [getState]
  );

  return React.useMemo(
    () => ({
      formOrFormGroupId,
      isFetching,
      resource,
      query,
      error,
    }),
    [formOrFormGroupId, isFetching, resource, query, error]
  );
}

export function useDataLogCreateRemovalRequest() {
  const { getState } = useStore<RootState>();
  const toast = useToast();
  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const createDataLogRemovalRequest = React.useCallback(
    async (formOrFormGroupId: string) => {
      const { resourceOwnerId } = getState().resourceOwner;

      try {
        const confirmed = await requestUserConfirmation(
          {
            titleId: "data_log_removal_request_modal.title",
            messageId: [
              "data_log_removal_request_modal.message1",
              "data_log_removal_request_modal.message2",
            ],
            actionId: "data_log_removal_request_modal.action",
            type: ConfirmModalType.Destory,
          },
          false
        );

        if (!confirmed) {
          return false;
        }

        await apiClient.createDataLogRemovalRequest({
          resourceOwnerId: resourceOwnerId ?? "",
          formOrFormGroupId,
        });

        toast.success("data_log_removal_request_modal.toast");
        return true;
      } catch (e) {
        const error = ensureFOCRError(e);
        toast.error(error.messageId, undefined, error.detail);
        return false;
      }
    },
    [getState, requestUserConfirmation, toast]
  );

  return {
    createDataLogRemovalRequest,
  };
}
